import Resource from "./resource.js";
export class TideIdentityProvider extends Resource {
    uploadImage = this.makeRequest({
        method: "POST",
        path: "/tide-idp-admin-resources/images/upload",
    });
    getImageName = this.makeRequest({
        method: "GET",
        path: "/tide-idp-admin-resources/images/{type}/name",
        urlParamKeys: ["type"],
        catchNotFound: true,
    });
    deleteImage = this.makeRequest({
        method: "DELETE",
        path: "/tide-idp-admin-resources/images/{type}/delete",
        urlParamKeys: ["type"],
        catchNotFound: true,
    });
    generateVrk = this.makeRequest({
        method: "POST",
        path: "/vendorResources/generate-vrk",
    });
    confirmVrk = this.makeRequest({
        method: "POST",
        path: "/vendorResources/confirm-vrk",
    });
    clearTempVrk = this.makeRequest({
        method: "POST",
        path: "/vendorResources/clear-temp-vrk",
    });
    generateVendorId = this.makeRequest({
        method: "POST",
        path: "/vendorResources/generate-vendor-id",
    });
    signMessage = this.makeRequest({
        method: "POST",
        path: "/vendorResources/sign-message",
    });
    constructor(client) {
        super(client, {
            path: "/admin/realms/{realm}",
            getUrlParams: () => ({
                realm: client.realmName,
            }),
            getBaseUrl: () => client.baseUrl,
        });
    }
}
