import { jsxs as b, jsx as t, Fragment as X } from "react/jsx-runtime";
import { AlertGroup as Rt, Alert as ve, AlertVariant as fe, AlertActionCloseButton as be, Page as Et, Modal as Ze, ModalVariant as Ot, Button as P, TextContent as Vt, Text as Ce, TextVariants as Dt, Spinner as Ye, FormHelperText as Ae, HelperText as Te, HelperTextItem as Fe, Popover as Pt, Icon as Ie, FormGroup as Qe, NumberInput as Nt, ValidatedOptions as q, InputGroup as Re, InputGroupItem as xe, TextInput as oe, Select as ce, MenuToggle as ee, MenuToggleStatus as Ee, SelectList as de, SelectOption as Oe, TextInputGroup as Xe, TextInputGroupMain as et, ChipGroup as tt, Chip as nt, TextInputGroupUtilities as rt, Switch as Mt, TextArea as at, Title as Lt, Card as ie, CardHeader as _t, CardTitle as J, CardBody as Z, Grid as qt, GridItem as ge, Stack as j, StackItem as z, ClipboardCopy as Be, FileUpload as Bt, PageSection as Ut, JumpLinks as Ht, JumpLinksItem as Gt, ButtonVariant as Ue, Checkbox as Kt, Radio as $t, MenuFooter as Wt, Dropdown as jt, DropdownList as zt, DropdownItem as He, Brand as Jt, Avatar as Zt } from "@patternfly/react-core";
import { createContext as Ve, useContext as De, useState as C, useCallback as lt, useEffect as ue, useMemo as L, useRef as he, forwardRef as Yt, useId as Qt, Fragment as it, Children as st } from "react";
import { useTranslation as Pe } from "react-i18next";
import Xt from "keycloak-js";
import { ExclamationCircleIcon as en, HelpIcon as Ge, EyeIcon as tn, EyeSlashIcon as nn, TimesIcon as ot, CubeIcon as rn, PaypalIcon as an, InstagramIcon as ln, BitbucketIcon as sn, MicrosoftIcon as on, TwitterIcon as cn, StackOverflowIcon as dn, OpenshiftIcon as un, LinkedinIcon as hn, GoogleIcon as pn, GitlabIcon as mn, FacebookSquareIcon as fn, GithubIcon as gn, MinusCircleIcon as yn, PlusCircleIcon as vn, EllipsisVIcon as bn } from "@patternfly/react-icons";
import { useFormContext as pe, Controller as $, useController as Ne, FormProvider as Cn, useWatch as In } from "react-hook-form";
import { get as Q } from "lodash-es";
import { NetworkError as xn } from "@keycloak/keycloak-admin-client";
import { PageHeader as kn, PageHeaderTools as wn, PageHeaderToolsGroup as Sn, PageHeaderToolsItem as ye } from "@patternfly/react-core/deprecated";
import re from "@patternfly/react-styles/css/components/Avatar/avatar";
import { css as An } from "@patternfly/react-styles";
import './main.css';const ct = Ve(void 0), Rr = () => De(ct), Tn = ({ children: e }) => {
  const [n, r] = C([]), a = (s) => {
    r((d) => d.filter((o) => o.id !== s));
  }, l = (s, d = fe.success, o) => {
    r([
      {
        id: Math.random() * 100,
        message: s,
        variant: d,
        description: o
      },
      ...n
    ]);
  }, i = (s) => {
    l(s, fe.danger);
  };
  return /* @__PURE__ */ b(ct.Provider, { value: { addAlert: l, addError: i }, children: [
    /* @__PURE__ */ t(Rt, { isToast: !0, "data-testid": "alerts", children: n.map(({ id: s, variant: d, message: o, description: u }) => /* @__PURE__ */ t(
      ve,
      {
        isLiveRegion: !0,
        variant: fe[d],
        variantLabel: "",
        title: o,
        actionClose: /* @__PURE__ */ t(
          be,
          {
            title: o,
            onClose: () => a(s)
          }
        ),
        timeout: !0,
        onTimeout: () => a(s),
        children: u && /* @__PURE__ */ t("p", { children: u })
      },
      s
    )) }),
    e
  ] });
}, Fn = (e) => {
  const { t: n } = Pe(), r = e.error, a = Rn(r);
  function l() {
    location.href = location.origin + location.pathname;
  }
  return /* @__PURE__ */ t(Et, { children: /* @__PURE__ */ t(
    Ze,
    {
      variant: Ot.small,
      title: n("somethingWentWrong"),
      titleIconVariant: "danger",
      showClose: !1,
      isOpen: !0,
      actions: [
        /* @__PURE__ */ t(P, { variant: "primary", onClick: l, children: n("tryAgain") }, "tryAgain")
      ],
      children: /* @__PURE__ */ b(Vt, { children: [
        /* @__PURE__ */ t(Ce, { children: n("somethingWentWrongDescription") }),
        a && /* @__PURE__ */ t(Ce, { component: Dt.small, children: a })
      ] })
    }
  ) });
};
function Rn(e) {
  return typeof e == "string" ? e : e instanceof Error ? e.message : null;
}
function En(e, n) {
  const r = Ve(n);
  return r.displayName = e, r;
}
function On(e) {
  return e != null;
}
function Vn(e) {
  const n = De(e);
  if (On(n))
    return n;
  throw new Error(
    `No provider found for ${e.displayName ? `the '${e.displayName}'` : "an unknown"} context, make sure it is included in your component hierarchy.`
  );
}
function Dn(e, n, r) {
  const [a, l] = C(
    () => e.getItem(n) ?? r
  ), i = lt((s) => {
    l(s), e.setItem(n, s);
  }, []);
  return ue(() => {
    l(e.getItem(n) ?? r), window.addEventListener("storage", s);
    function s(d) {
      d.storageArea === e && (d.key === null || d.key === n) && l(d.newValue ?? r);
    }
    return () => window.removeEventListener("storage", s);
  }, [e, n]), [a, i];
}
function Pn(e, n, r) {
  const a = L(
    () => JSON.stringify(r),
    [r]
  ), [l, i] = Dn(
    e,
    n,
    a
  ), s = L(() => JSON.parse(l), [l]), d = lt(
    (o) => i(JSON.stringify(o)),
    []
  );
  return [s, d];
}
const dt = En(
  "HelpContext",
  void 0
), Nn = () => Vn(dt), Mn = ({ children: e }) => {
  const [n, r] = Pn(localStorage, "helpEnabled", !0);
  function a() {
    r(!n);
  }
  return /* @__PURE__ */ t(dt.Provider, { value: { enabled: n, toggleHelp: a }, children: e });
}, Ln = () => Ve(void 0);
let ke;
const Er = () => {
  const e = De(ke);
  if (!e)
    throw Error(
      "no environment provider in the hierarchy make sure to add the provider"
    );
  return e;
}, Or = ({
  environment: e,
  children: n
}) => {
  ke = Ln();
  const r = he(!1), [a, l] = C(!1), [i, s] = C(), d = L(() => {
    const o = new Xt({
      url: e.authServerUrl,
      realm: e.realm,
      clientId: e.clientId
    });
    return o.onAuthLogout = () => o.login(), o;
  }, [e]);
  return ue(() => {
    if (r.current)
      return;
    d.init({
      onLoad: "check-sso",
      pkceMethod: "S256",
      responseMode: "query"
    }).then(() => l(!0)).catch((u) => s(u)), r.current = !0;
  }, [d]), i ? /* @__PURE__ */ t(Fn, { error: i }) : a ? /* @__PURE__ */ t(ke.Provider, { value: { environment: e, keycloak: d }, children: /* @__PURE__ */ t(Tn, { children: /* @__PURE__ */ t(Mn, { children: n }) }) }) : /* @__PURE__ */ t(Ye, {});
};
function Vr(e) {
  const n = document.getElementById("environment");
  let r = {};
  try {
    n?.textContent && (r = JSON.parse(n.textContent));
  } catch {
    console.error("Unable to parse environment variables.");
  }
  return { ...e, ...r };
}
const Dr = ({
  modalTitle: e,
  continueLabel: n,
  cancelLabel: r,
  buttonTitle: a,
  isDisabled: l,
  buttonVariant: i,
  buttonTestRole: s,
  onContinue: d,
  component: o = P,
  children: u,
  ...m
}) => {
  const [y, h] = C(!1);
  return /* @__PURE__ */ b(X, { children: [
    /* @__PURE__ */ t(
      o,
      {
        variant: i,
        onClick: () => h(!0),
        isDisabled: l,
        "data-testrole": s,
        children: a
      }
    ),
    /* @__PURE__ */ t(
      Ze,
      {
        variant: "small",
        ...m,
        title: e,
        isOpen: y,
        onClose: () => h(!1),
        actions: [
          /* @__PURE__ */ t(
            P,
            {
              id: "modal-confirm",
              variant: "primary",
              onClick: () => {
                h(!1), d();
              },
              children: n
            },
            "confirm"
          ),
          /* @__PURE__ */ t(
            P,
            {
              id: "modal-cancel",
              variant: "secondary",
              onClick: () => h(!1),
              children: r
            },
            "cancel"
          )
        ],
        children: u
      }
    )
  ] });
}, ut = ({ message: e, ...n }) => /* @__PURE__ */ t(Ae, { ...n, children: /* @__PURE__ */ t(Te, { children: /* @__PURE__ */ t(Fe, { icon: /* @__PURE__ */ t(en, {}), variant: "error", children: e }) }) }), ht = ({
  helpText: e,
  fieldLabelId: n,
  noVerticalAlign: r = !0,
  unWrap: a = !1
}) => {
  const { enabled: l } = Nn();
  return l ? /* @__PURE__ */ t(Pt, { bodyContent: e, children: /* @__PURE__ */ b(X, { children: [
    !a && /* @__PURE__ */ t(
      "button",
      {
        "data-testid": `help-label-${n}`,
        "aria-label": n,
        onClick: (i) => i.preventDefault(),
        className: "pf-v5-c-form__group-label-help",
        children: /* @__PURE__ */ t(Ie, { isInline: r, children: /* @__PURE__ */ t(Ge, {}) })
      }
    ),
    a && /* @__PURE__ */ t(Ie, { isInline: r, children: /* @__PURE__ */ t(Ge, {}) })
  ] }) }) : null;
}, G = ({
  name: e,
  label: n,
  labelIcon: r,
  error: a,
  children: l,
  ...i
}) => /* @__PURE__ */ b(
  Qe,
  {
    label: n || e,
    fieldId: e,
    labelIcon: r ? /* @__PURE__ */ t(ht, { helpText: r, fieldLabelId: e }) : void 0,
    ...i,
    children: [
      l,
      a && /* @__PURE__ */ t(ut, { "data-testid": `${e}-helper`, message: a.message })
    ]
  }
), Pr = ({
  name: e,
  label: n,
  controller: r,
  labelIcon: a,
  ...l
}) => {
  const {
    control: i,
    formState: { errors: s }
  } = pe();
  return /* @__PURE__ */ t(
    G,
    {
      name: e,
      label: n,
      isRequired: r.rules?.required === !0,
      error: s[e],
      labelIcon: a,
      children: /* @__PURE__ */ t(
        $,
        {
          ...r,
          name: e,
          control: i,
          render: ({ field: d }) => {
            const o = !!r.rules?.required, u = r.rules?.min, m = d.value === 0 ? r.defaultValue : d.value, y = (h) => d.onChange(u ? Math.max(h, Number(u)) : h);
            return /* @__PURE__ */ t(
              Nt,
              {
                ...l,
                id: e,
                value: m,
                validated: s[e] ? q.error : q.default,
                required: o,
                min: Number(u),
                max: Number(r.rules?.max),
                onPlus: () => y(m + 1),
                onMinus: () => y(m - 1),
                onChange: (h) => {
                  const c = Number(h.currentTarget.value);
                  y(isNaN(c) ? r.defaultValue : c);
                }
              }
            );
          }
        }
      )
    }
  );
}, _n = ({
  hasReveal: e = !0,
  innerRef: n,
  isTideIdp: r = !1,
  ...a
}) => {
  const { t: l } = Pe(), [i, s] = C(!0);
  return /* @__PURE__ */ b(Re, { style: { display: r ? "none" : void 0 }, children: [
    /* @__PURE__ */ t(xe, { isFill: !0, children: /* @__PURE__ */ t(
      oe,
      {
        ...a,
        type: i ? "password" : "text",
        ref: n
      }
    ) }),
    e && /* @__PURE__ */ t(
      P,
      {
        variant: "control",
        "aria-label": l("showPassword"),
        onClick: () => s(!i),
        children: i ? /* @__PURE__ */ t(tn, {}) : /* @__PURE__ */ t(nn, {})
      }
    )
  ] });
}, pt = Yt(
  (e, n) => /* @__PURE__ */ t(_n, { ...e, innerRef: n })
);
pt.displayName = "PasswordInput";
const Nr = (e) => {
  const { labelIcon: n, ...r } = e, a = !!e.rules?.required, l = e.defaultValue ?? "", { field: i, fieldState: s } = Ne({
    ...e,
    defaultValue: l
  }), d = e.isTideIdp ?? !1;
  return /* @__PURE__ */ b(
    G,
    {
      style: { display: d ? "none" : void 0 },
      name: e.name,
      label: e.label,
      labelIcon: n,
      isRequired: a,
      error: s.error,
      children: [
        /* @__PURE__ */ t(
          pt,
          {
            isTideIdp: d,
            isRequired: a,
            id: e.name,
            "data-testid": e.name,
            validated: s.error ? q.error : q.default,
            isDisabled: e.isDisabled,
            ...r,
            ...i
          }
        ),
        e.helperText && /* @__PURE__ */ t(Ae, { children: /* @__PURE__ */ t(Te, { children: /* @__PURE__ */ t(Fe, { children: e.helperText }) }) })
      ]
    }
  );
}, qn = ({
  id: e,
  name: n,
  label: r,
  options: a,
  controller: l,
  labelIcon: i,
  ...s
}) => {
  const {
    control: d,
    formState: { errors: o }
  } = pe(), [u, m] = C(!1);
  return /* @__PURE__ */ t(
    G,
    {
      name: n,
      label: r,
      isRequired: !!l.rules?.required,
      error: Q(o, n),
      labelIcon: i,
      children: /* @__PURE__ */ t(
        $,
        {
          ...l,
          name: n,
          control: d,
          render: ({ field: { onChange: y, value: h } }) => /* @__PURE__ */ t(
            ce,
            {
              ...s,
              onClick: () => m(!u),
              onOpenChange: () => m(!1),
              selected: Y(a) ? a.filter(
                (c) => Array.isArray(h) ? h.includes(c.key) : h === c.key
              ).map((c) => c.value) : h,
              toggle: (c) => /* @__PURE__ */ t(
                ee,
                {
                  id: e || n.slice(n.lastIndexOf(".") + 1),
                  ref: c,
                  onClick: () => m(!u),
                  isExpanded: u,
                  isFullWidth: !0,
                  status: Q(o, n) ? Ee.danger : void 0,
                  "aria-label": "toggle",
                  children: Y(a) ? a.find(
                    (T) => T.key === (Array.isArray(h) ? h[0] : h)
                  )?.value : h
                }
              ),
              onSelect: (c, T) => {
                const p = T?.toString();
                y(Array.isArray(h) ? [p] : p), m(!1);
              },
              isOpen: u,
              children: /* @__PURE__ */ t(de, { children: a.map((c) => /* @__PURE__ */ t(Oe, { value: U(c), children: Me(c) ? c : c.value }, U(c))) })
            }
          )
        }
      )
    }
  );
}, ae = (e) => Me(e) ? e : e.value, Bn = ({
  id: e,
  name: n,
  label: r,
  options: a,
  controller: l,
  labelIcon: i,
  placeholderText: s,
  onFilter: d,
  variant: o,
  ...u
}) => {
  const {
    control: m,
    formState: { errors: y }
  } = pe(), [h, c] = C(!1), [T, p] = C(""), [g, I] = C(0), N = he(), v = a.filter(
    (f) => ae(f).toLowerCase().startsWith(T.toLowerCase())
  ), x = L(
    () => v.map((f, w) => /* @__PURE__ */ t(
      Oe,
      {
        value: U(f),
        isFocused: g === w,
        children: ae(f)
      },
      U(f)
    )),
    [g, v]
  ), O = (f, w) => {
    const F = v[g];
    switch (c(!0), f.key) {
      case "Enter": {
        f.preventDefault(), o !== K.typeaheadMulti ? p(ae(F)) : p(""), w.onChange(
          Array.isArray(w.value) ? [...w.value, U(F)] : U(F)
        ), c(!1), I(0);
        break;
      }
      case "Tab":
      case "Escape": {
        c(!1), w.onChange(void 0);
        break;
      }
      case "Backspace": {
        o === K.typeahead && w.onChange("");
        break;
      }
      case "ArrowUp":
      case "ArrowDown": {
        f.preventDefault();
        let A = 0;
        f.key === "ArrowUp" && (g === 0 ? A = a.length - 1 : A = g - 1), f.key === "ArrowDown" && (g === a.length - 1 ? A = 0 : A = g + 1), I(A);
        break;
      }
    }
  };
  return /* @__PURE__ */ t(
    G,
    {
      name: n,
      label: r,
      isRequired: !!l.rules?.required,
      error: Q(y, n),
      labelIcon: i,
      children: /* @__PURE__ */ t(
        $,
        {
          ...l,
          name: n,
          control: m,
          render: ({ field: f }) => /* @__PURE__ */ t(
            ce,
            {
              ...u,
              onClick: () => c(!h),
              onOpenChange: () => c(!1),
              selected: Y(a) ? a.filter(
                (w) => Array.isArray(f.value) ? f.value.includes(w.key) : f.value === w.key
              ).map((w) => w.value) : f.value,
              toggle: (w) => /* @__PURE__ */ t(
                ee,
                {
                  ref: w,
                  id: e || n.slice(n.lastIndexOf(".") + 1),
                  variant: "typeahead",
                  onClick: () => c(!h),
                  isExpanded: h,
                  isFullWidth: !0,
                  status: Q(y, n) ? Ee.danger : void 0,
                  children: /* @__PURE__ */ b(Xe, { isPlain: !0, children: [
                    /* @__PURE__ */ t(
                      et,
                      {
                        placeholder: s,
                        value: o === K.typeahead && f.value ? Y(a) ? a.find(
                          (F) => F.key === (Array.isArray(f.value) ? f.value[0] : f.value)
                        )?.value : f.value : T,
                        onClick: () => c(!h),
                        onChange: (F, A) => {
                          p(A), d?.(A);
                        },
                        onKeyDown: (F) => O(F, f),
                        autoComplete: "off",
                        innerRef: N,
                        role: "combobox",
                        isExpanded: h,
                        "aria-controls": "select-typeahead-listbox",
                        children: o === K.typeaheadMulti && Array.isArray(f.value) && /* @__PURE__ */ t(tt, { "aria-label": "Current selections", children: f.value.map(
                          (F, A) => /* @__PURE__ */ t(
                            nt,
                            {
                              onClick: (R) => {
                                R.stopPropagation(), f.onChange(
                                  f.value.filter(
                                    (E) => E !== U(F)
                                  )
                                );
                              },
                              children: Y(a) ? a.find((R) => F === R.key)?.value : ae(F)
                            },
                            A
                          )
                        ) })
                      }
                    ),
                    /* @__PURE__ */ t(rt, { children: !!T && /* @__PURE__ */ t(
                      P,
                      {
                        variant: "plain",
                        onClick: () => {
                          f.onChange(void 0), p(""), N?.current?.focus();
                        },
                        "aria-label": "Clear input value",
                        children: /* @__PURE__ */ t(ot, { "aria-hidden": !0 })
                      }
                    ) })
                  ] })
                }
              ),
              onSelect: (w, F) => {
                w?.stopPropagation();
                const A = F?.toString();
                o === K.typeaheadMulti && Array.isArray(f.value) ? f.value.includes(A) ? f.onChange(
                  f.value.filter((R) => R !== A)
                ) : f.onChange([...f.value, A]) : (f.onChange(Array.isArray(f.value) ? [A] : A), c(!1));
              },
              isOpen: h,
              children: /* @__PURE__ */ t(de, { children: x })
            }
          )
        }
      )
    }
  );
};
var K = /* @__PURE__ */ ((e) => (e.single = "single", e.typeahead = "typeahead", e.typeaheadMulti = "typeaheadMulti", e))(K || {});
const Y = (e) => typeof e[0] != "string", Me = (e) => typeof e == "string", U = (e) => Me(e) ? e : e.key, Un = ({
  variant: e = "single",
  ...n
}) => e === "single" ? /* @__PURE__ */ t(qn, { ...n }) : /* @__PURE__ */ t(Bn, { ...n, variant: e }), Mr = ({
  labelOn: e,
  stringify: n,
  defaultValue: r,
  labelIcon: a,
  ...l
}) => {
  const s = r ?? (n ? "false" : !1), { control: d } = pe();
  return /* @__PURE__ */ t(
    G,
    {
      hasNoPaddingTop: !0,
      name: l.name,
      isRequired: l.rules?.required === !0,
      label: l.label,
      labelIcon: a,
      children: /* @__PURE__ */ t(
        $,
        {
          control: d,
          name: l.name,
          defaultValue: s,
          render: ({ field: { onChange: o, value: u } }) => /* @__PURE__ */ t(
            Mt,
            {
              ...l,
              id: l.name,
              "data-testid": l.name,
              label: e,
              isChecked: n ? u === "true" : u,
              onChange: (m, y) => {
                const h = n ? y.toString() : y;
                l.onChange?.(m, y), o(h);
              }
            }
          )
        }
      )
    }
  );
}, Lr = (e) => {
  const n = !!e.rules?.required, r = e.defaultValue ?? "", { field: a, fieldState: l } = Ne({
    ...e,
    defaultValue: r
  });
  return /* @__PURE__ */ t(
    G,
    {
      isRequired: n,
      label: e.label,
      labelIcon: e.labelIcon,
      name: e.name,
      error: l.error,
      children: /* @__PURE__ */ t(
        at,
        {
          isRequired: n,
          id: e.name,
          "data-testid": e.name,
          validated: l.error ? q.error : q.default,
          isDisabled: e.isDisabled,
          ...a
        }
      )
    }
  );
}, _r = (e) => {
  const { labelIcon: n, ...r } = e, a = !!e.rules?.required, l = e.defaultValue ?? "", { field: i, fieldState: s } = Ne({
    ...e,
    defaultValue: l
  });
  return /* @__PURE__ */ b(
    G,
    {
      name: e.name,
      label: e.label,
      labelIcon: n,
      isRequired: a,
      error: s.error,
      children: [
        /* @__PURE__ */ t(
          oe,
          {
            isRequired: a,
            id: e.name,
            "data-testid": e.name,
            validated: s.error ? q.error : q.default,
            isDisabled: e.isDisabled,
            ...r,
            ...i
          }
        ),
        e.helperText && /* @__PURE__ */ t(Ae, { children: /* @__PURE__ */ t(Te, { children: /* @__PURE__ */ t(Fe, { children: e.helperText }) }) })
      ]
    }
  );
}, Hn = at, qr = ({ icon: e }) => {
  const n = Gn(e);
  return /* @__PURE__ */ t(Ie, { size: "lg", children: /* @__PURE__ */ t(n, { alt: e }) });
};
function Gn(e) {
  switch (e) {
    case "github":
      return gn;
    case "facebook":
      return fn;
    case "gitlab":
      return mn;
    case "google":
      return pn;
    case "linkedin":
    case "linkedin-openid-connect":
      return hn;
    case "openshift-v3":
    case "openshift-v4":
      return un;
    case "stackoverflow":
      return dn;
    case "twitter":
      return cn;
    case "microsoft":
      return on;
    case "bitbucket":
      return sn;
    case "instagram":
      return ln;
    case "paypal":
      return an;
    default:
      return rn;
  }
}
const Kn = "_title_180i0_2", $n = {
  title: Kn
}, mt = ({
  id: e,
  title: n,
  headingLevel: r = "h1",
  size: a = "xl",
  ...l
}) => /* @__PURE__ */ t(
  Lt,
  {
    headingLevel: r,
    size: a,
    className: $n.title,
    id: e,
    tabIndex: 0,
    ...l,
    children: n
  }
), Wn = ({
  title: e,
  children: n,
  scrollId: r,
  className: a
}) => {
  const l = Qt();
  return /* @__PURE__ */ b(ie, { id: l, className: a, isFlat: !0, children: [
    /* @__PURE__ */ t(_t, { className: "kc-form-panel__header", children: /* @__PURE__ */ t(J, { tabIndex: 0, children: /* @__PURE__ */ t(mt, { id: r, title: e }) }) }),
    /* @__PURE__ */ t(Z, { className: "kc-form-panel__body", children: n })
  ] });
}, jn = (e) => {
  const { title: n, children: r, scrollId: a, ...l } = e;
  return /* @__PURE__ */ t("section", { ...l, style: { marginTop: "var(--pf-v5-global--spacer--lg)" }, children: /* @__PURE__ */ b(X, { children: [
    /* @__PURE__ */ t(mt, { id: a, title: n }),
    r
  ] }) });
}, zn = "_panel_1cdve_1", Jn = "_sticky_1cdve_5", Ke = {
  panel: zn,
  sticky: Jn
}, Zn = "kc-main-content-page-container", $e = (e) => e.replace(/\s+/g, "-"), Yn = ({
  label: e,
  sections: n,
  borders: r = !1,
  submitVendorCallback: a,
  tideIdp: l = !1,
  tideIdpDetails: i,
  newLicenseRequest: s,
  saveActivationPackageCallback: d,
  clearActivationPackageCallback: o,
  rotateKeyCallback: u,
  isButtonDisabled: m = !0,
  ...y
}) => {
  const [h, c] = C(!1), [T, p] = C(!1), [g, I] = C(""), [N, v] = C(""), [x, O] = C(!1), [f, w] = C(""), [F, A] = C(""), [R, E] = C(""), [V, W] = C(""), [vt, Le] = C(!1), [bt, Ct] = C(!0), It = (k, S) => {
    W(S.name);
  }, xt = async (k, S) => {
    try {
      if (d)
        await d(S), E(S), c(!1);
      else
        throw new Error("savePayerKeyCallback is not defined");
    } catch (D) {
      p(!1);
      const ne = le(D);
      v("Error importing activation package"), I(ne ?? ""), c(!0);
    }
  }, kt = async (k) => {
    try {
      o && (await o(R), E(""), W(""));
    } catch (S) {
      p(!1);
      const D = le(S);
      v("Error clearing activation package"), I(D ?? ""), c(!0);
    }
  }, wt = (k, S) => {
    Le(!0);
  }, St = (k, S) => {
    Le(!1);
  }, _e = L(
    () => n.filter(({ isHidden: k }) => !k),
    [n]
  );
  ue(() => {
    const k = JSON.stringify(
      {
        gVRK: i?.gVRK || "",
        vendorId: i?.vendorId || "",
        payerPublic: i?.payerPublic || ""
      },
      null,
      2
    ), S = JSON.stringify(
      {
        gVRK: s?.tempgVRK || "",
        vendorId: s?.tempVendorId || ""
      },
      null,
      2
    );
    w(k), A(S), Ct(!qe());
  }, [i]);
  const qe = () => s !== void 0 && s.tempgVRK !== "" && s.tempVendorId !== "", At = async (k) => {
    try {
      O(!0), qe() && (R === void 0 || R === "") ? (p(!1), v("Activation package has not been provided"), I("Provide the details to Ziva to receive your activation package to continue"), c(!0)) : (await k(), E(""), W(""), c(!1), p(!0), v("Settings Secured"), I("Settings have been signed successfully")), O(!1);
    } catch (S) {
      O(!1), p(!1);
      const D = le(S);
      v("Error Securing Signatures"), I(D ?? ""), c(!0);
    }
  }, Tt = async (k) => {
    try {
      O(!0), await k(), c(!1), p(!0), v("New License Request Generated"), I("Provide these new details to Ziva"), O(!1);
    } catch (S) {
      O(!1), p(!1);
      const D = le(S);
      v("Error Generating License Request"), I(D ?? ""), c(!0);
    }
  }, Ft = ({ title: k, hasSuccess: S, hasError: D, message: ne }) => /* @__PURE__ */ b(X, { children: [
    D && /* @__PURE__ */ t(
      ve,
      {
        style: { margin: "0px 10px 10px 10px" },
        variant: "danger",
        isInline: !0,
        title: k,
        actionClose: /* @__PURE__ */ t(be, { onClose: () => c(!1) }),
        children: ne
      }
    ),
    S && /* @__PURE__ */ t(
      ve,
      {
        style: { margin: "0px 10px 10px 10px" },
        variant: "success",
        isInline: !0,
        title: k,
        actionClose: /* @__PURE__ */ t(be, { onClose: () => p(!1) }),
        children: ne
      }
    )
  ] });
  return /* @__PURE__ */ b(qt, { hasGutter: !0, ...y, children: [
    /* @__PURE__ */ t(ge, { md: 8, sm: 12, children: _e.map(({ title: k, panel: S }) => {
      const D = $e(k.toLowerCase());
      return /* @__PURE__ */ t(it, { children: r ? /* @__PURE__ */ t(
        Wn,
        {
          scrollId: D,
          title: k,
          className: Ke.panel,
          children: S
        }
      ) : /* @__PURE__ */ t(jn, { scrollId: D, title: k, children: S }) }, k);
    }) }),
    /* @__PURE__ */ b(ge, { md: 4, sm: 12, order: { default: "-1", md: "1" }, children: [
      l === !0 && /* @__PURE__ */ t(ge, { children: /* @__PURE__ */ b(ie, { style: { marginTop: "var(--pf-v5-global--spacer--lg)" }, children: [
        /* @__PURE__ */ t(J, { component: "h1", style: { textAlign: "center", fontSize: "1.25rem" }, children: "Vendor Sign Up" }),
        /* @__PURE__ */ t(Ft, { title: N, hasSuccess: T, hasError: h, message: g }),
        /* @__PURE__ */ b(ie, { style: { margin: "0px 10px 10px 10px" }, children: [
          /* @__PURE__ */ t(J, { style: { textAlign: "center" }, children: "Active License" }),
          /* @__PURE__ */ t(Z, { children: /* @__PURE__ */ t(j, { hasGutter: !0, style: { alignItems: "center" }, children: /* @__PURE__ */ t(z, { style: { width: "100%" }, children: i?.vvkId !== "" ? /* @__PURE__ */ t(Be, { isReadOnly: !0, isCode: !0, children: f }) : /* @__PURE__ */ t("p", { children: " No Active License, request a new license and provide the details to Ziva" }) }) }) })
        ] }),
        /* @__PURE__ */ b(ie, { style: { margin: "0px 10px 10px 10px" }, children: [
          /* @__PURE__ */ t(J, { style: { textAlign: "center" }, children: "Provide these details to Ziva" }),
          /* @__PURE__ */ t(Z, { children: /* @__PURE__ */ t(j, { hasGutter: !0, style: { alignItems: "center" }, children: /* @__PURE__ */ t(z, { style: { width: "100%" }, children: /* @__PURE__ */ t(Be, { isReadOnly: !0, isCode: !0, children: F }) }) }) }),
          /* @__PURE__ */ t(J, { style: { textAlign: "center" }, children: "Import Activation Package" }),
          /* @__PURE__ */ t(Z, { children: /* @__PURE__ */ t(j, { hasGutter: !0, style: { alignItems: "center" }, children: /* @__PURE__ */ t(z, { style: { width: "100%", alignItems: "center" }, children: /* @__PURE__ */ t(
            Bt,
            {
              id: "text-file-simple",
              type: "text",
              value: R,
              filename: V,
              filenamePlaceholder: "Drag and drop a file or upload one",
              onFileInputChange: It,
              onDataChange: xt,
              onReadStarted: wt,
              onReadFinished: St,
              onClearClick: kt,
              isLoading: vt,
              allowEditingUploadedText: !1,
              browseButtonText: "Import",
              hideDefaultPreview: !0,
              isDisabled: bt
            }
          ) }) }) })
        ] }),
        /* @__PURE__ */ t(Z, { children: x ? /* @__PURE__ */ t(j, { hasGutter: !0, style: { alignItems: "center" }, children: /* @__PURE__ */ t(Ye, { size: "xl" }) }) : /* @__PURE__ */ b(j, { hasGutter: !0, style: { alignItems: "center" }, children: [
          /* @__PURE__ */ t(z, { children: /* @__PURE__ */ t(P, { onClick: () => Tt(u ?? (() => {
          })), children: "New License Request" }) }),
          /* @__PURE__ */ t(z, { children: /* @__PURE__ */ t(P, { isDisabled: m, onClick: () => At(a ?? (() => {
          })), children: "Secure Settings" }) })
        ] }) })
      ] }) }),
      /* @__PURE__ */ t(Ut, { className: Ke.sticky, style: { marginTop: "var(--pf-v5-global--spacer--lg)" }, children: /* @__PURE__ */ t(
        Ht,
        {
          isVertical: !0,
          scrollableSelector: `#${Zn}`,
          label: e,
          offset: 100,
          children: _e.map(({ title: k }) => {
            const S = $e(k.toLowerCase());
            return (
              // note that JumpLinks currently does not work with spaces in the href
              /* @__PURE__ */ t(
                Gt,
                {
                  href: `#${S}`,
                  "data-testid": `jump-link-${S}`,
                  children: k
                },
                k
              )
            );
          })
        }
      ) })
    ] })
  ] });
};
function le(e) {
  if (typeof e == "string")
    return e;
  if (e instanceof xn)
    return Qn(e);
  if (e instanceof Error)
    return e.message;
  throw new Error("Unable to determine error message.");
}
function Qn({ responseData: e }) {
  const n = e;
  for (const r of ["error_description", "errorMessage", "error"]) {
    const a = n[r];
    if (typeof a == "string")
      return a;
  }
}
const Xn = (e, n, r) => (e.isValid || r) && (e.isDirty || n) && !e.isLoading && !e.isValidating && !e.isSubmitting, Br = ({
  formState: e,
  isDisabled: n = !1,
  allowInvalid: r = !1,
  allowNonDirty: a = !1,
  children: l,
  ...i
}) => /* @__PURE__ */ t(
  P,
  {
    variant: "primary",
    isDisabled: e && !Xn(e, a, r) || n,
    ...i,
    type: "submit",
    children: l
  }
), er = (e) => {
  try {
    return new Intl.DisplayNames([e], { type: "language" }).of(e);
  } catch {
    return e;
  }
}, tr = ({
  t: e,
  form: n,
  supportedLocales: r,
  currentLocale: a
}) => {
  const l = L(
    () => r.map((i) => ({
      key: i,
      value: e(`locale_${i}`, er(i) ?? i)
    })).sort((i, s) => i.value.localeCompare(s.value, a)),
    [r, a, e]
  );
  return l.length ? /* @__PURE__ */ t(Cn, { ...n, children: /* @__PURE__ */ t(
    Un,
    {
      "data-testid": "locale-select",
      name: "attributes.locale",
      label: e("selectALocale"),
      controller: { defaultValue: "" },
      options: l,
      variant: l.length >= 10 ? "typeahead" : "single"
    }
  ) }) : null;
}, ft = (e) => e?.includes("${"), gt = (e) => e.substring(2, e.length - 1), H = (e, n, r) => (ft(n) ? e(gt(n)) : n) || r, we = (e, n) => H(e, n.displayName, n.name), nr = ["username", "firstName", "lastName", "email"], yt = (e) => e && nr.includes(e), B = (e) => `${yt(e) ? "" : "attributes."}${e?.replaceAll(
  ".",
  "🍺"
)}`, Ur = (e) => e.replaceAll(".", "🍺"), Hr = (e) => e.replaceAll("🍺", ".");
function Gr(e, n, r) {
  (e.responseData.errors !== void 0 ? e.responseData.errors : [e.responseData]).forEach((a) => {
    const l = Object.assign(
      {},
      a.params?.map((i) => r(ft(i.toString()) ? gt(i) : i))
    );
    n(B(a.field), {
      message: r(a.errorMessage, {
        ...l,
        defaultValue: a.errorMessage || a.field
      }),
      type: "server"
    });
  });
}
function me({
  required: e,
  validators: n
}) {
  return e || rr(n);
}
function rr(e) {
  return e && "length" in e && "min" in e.length && typeof e.length.min == "number" ? e.length.min > 0 : !1;
}
function Kr(e) {
  if (typeof e != "object" || e === null || !("responseData" in e))
    return !1;
  const { responseData: n } = e;
  return We(n) ? !0 : typeof n != "object" || n === null || !("errors" in n) || !Array.isArray(n.errors) ? !1 : n.errors.every(We);
}
function We(e) {
  return !(typeof e != "object" || e === null || !("field" in e) || typeof e.field != "string" || !("errorMessage" in e) || typeof e.errorMessage != "string");
}
const te = ({
  t: e,
  form: n,
  attribute: r,
  renderer: a,
  children: l
}) => {
  const i = H(
    e,
    r.annotations?.inputHelperTextBefore
  ), {
    formState: { errors: s }
  } = n, d = a?.(r), o = Q(s, B(r.name));
  return /* @__PURE__ */ b(
    Qe,
    {
      label: we(e, r) || "",
      fieldId: r.name,
      isRequired: me(r),
      labelIcon: i ? /* @__PURE__ */ t(ht, { helpText: i, fieldLabelId: r.name }) : void 0,
      children: [
        d ? /* @__PURE__ */ b(Re, { children: [
          l,
          d
        ] }) : l,
        o && /* @__PURE__ */ t(
          ut,
          {
            "data-testid": `${r.name}-helper`,
            message: o.message
          }
        )
      ]
    },
    r.name
  );
}, ar = ({
  t: e,
  form: n,
  attribute: r,
  renderer: a,
  ...l
}) => /* @__PURE__ */ t(te, { t: e, form: n, attribute: r, renderer: a, children: /* @__PURE__ */ t(
  lr,
  {
    t: e,
    form: n,
    "aria-label": we(e, r),
    name: B(r.name),
    addButtonLabel: e("addMultivaluedLabel", {
      fieldLabel: we(e, r)
    }),
    ...l
  }
) }), lr = ({
  t: e,
  name: n,
  inputType: r,
  form: a,
  addButtonLabel: l,
  isDisabled: i = !1,
  defaultValue: s,
  id: d,
  ...o
}) => {
  const { register: u, setValue: m, control: y } = a, h = In({
    name: n,
    control: y,
    defaultValue: s || ""
  }), c = L(() => Array.isArray(h) && h.length !== 0 ? h : s || [""], [h]), T = (v) => {
    I([...c.slice(0, v), ...c.slice(v + 1)]);
  }, p = () => {
    I([...c, ""]);
  }, g = (v, x) => {
    I([...c.slice(0, v), x, ...c.slice(v + 1)]);
  }, I = (v) => {
    const x = v.flatMap((O) => O);
    m(n, x, {
      shouldDirty: !0
    });
  }, N = r.startsWith("html") ? r.substring(6) : "text";
  return ue(() => {
    u(n);
  }, [u]), /* @__PURE__ */ t("div", { id: d, children: c.map((v, x) => /* @__PURE__ */ b(it, { children: [
    /* @__PURE__ */ b(Re, { children: [
      /* @__PURE__ */ t(xe, { isFill: !0, children: /* @__PURE__ */ t(
        oe,
        {
          "data-testid": n + x,
          onChange: (O, f) => g(x, f),
          name: `${n}.${x}.value`,
          value: v,
          isDisabled: i,
          type: N,
          ...o
        }
      ) }),
      /* @__PURE__ */ t(xe, { children: /* @__PURE__ */ t(
        P,
        {
          "data-testid": "remove" + x,
          variant: Ue.link,
          onClick: () => T(x),
          tabIndex: -1,
          "aria-label": e("remove"),
          isDisabled: c.length === 1 || i,
          children: /* @__PURE__ */ t(yn, {})
        }
      ) })
    ] }),
    x === c.length - 1 && /* @__PURE__ */ b(
      P,
      {
        variant: Ue.link,
        onClick: p,
        tabIndex: -1,
        "aria-label": e("add"),
        "data-testid": "addValue",
        isDisabled: !v || i,
        children: [
          /* @__PURE__ */ t(vn, {}),
          " ",
          e(l || "add")
        ]
      }
    )
  ] }, x)) });
}, je = (e) => {
  const { form: n, inputType: r, attribute: a } = e, l = me(a), i = r.startsWith("multiselect"), s = i ? Kt : $t, d = a.validators?.options?.options || [], o = a.annotations?.inputOptionLabels || {};
  return /* @__PURE__ */ t(te, { ...e, children: /* @__PURE__ */ t(
    $,
    {
      name: B(a.name),
      control: n.control,
      defaultValue: "",
      render: ({ field: u }) => /* @__PURE__ */ t(X, { children: d.map((m) => /* @__PURE__ */ t(
        s,
        {
          id: m,
          "data-testid": m,
          label: H(e.t, o[m], m),
          value: m,
          isChecked: u.value.includes(m),
          onChange: () => {
            i ? u.value.includes(m) ? u.onChange(
              u.value.filter((y) => y !== m)
            ) : u.onChange([...u.value, m]) : u.onChange([m]);
          },
          readOnly: a.readOnly,
          isRequired: l
        },
        m
      )) })
    }
  ) });
}, ir = ({
  toggleId: e,
  onToggle: n,
  onSelect: r,
  selections: a,
  isOpen: l,
  menuAppendTo: i,
  direction: s,
  width: d,
  maxHeight: o,
  toggleIcon: u,
  className: m,
  children: y,
  ...h
}) => {
  const [c, T] = C(!1), p = he(), g = () => {
    T(!c), n(!c);
  }, I = () => i === "parent" && p.current?.parentElement || "inline", N = st.toArray(
    y
  );
  return /* @__PURE__ */ t(
    ce,
    {
      ref: p,
      maxMenuHeight: se(o),
      isScrollable: !0,
      popperProps: {
        appendTo: I(),
        direction: s,
        width: se(d)
      },
      ...h,
      onClick: g,
      onOpenChange: () => T(!1),
      selected: a,
      onSelect: (v, x) => {
        r?.(x || ""), g();
      },
      toggle: (v) => /* @__PURE__ */ t(
        ee,
        {
          id: e,
          ref: v,
          className: m,
          onClick: g,
          isExpanded: l,
          "aria-label": h["aria-label"],
          icon: u,
          isFullWidth: !0,
          children: N.find((x) => x.props.value === a)?.props.children || a || h["aria-label"]
        }
      ),
      isOpen: l,
      children: /* @__PURE__ */ t(de, { children: y })
    }
  );
}, sr = ({
  toggleId: e,
  onSelect: n,
  onToggle: r,
  onFilter: a,
  variant: l,
  validated: i,
  placeholderText: s,
  maxHeight: d,
  width: o,
  toggleIcon: u,
  direction: m,
  selections: y,
  typeAheadAriaLabel: h,
  chipGroupComponent: c,
  chipGroupProps: T,
  footer: p,
  children: g,
  ...I
}) => {
  const [N, v] = C(""), [x, O] = C(0), f = he(), w = st.toArray(
    g
  ), F = () => {
    r?.(!I.isOpen);
  }, A = (R) => {
    const E = w[x];
    switch (r?.(!0), R.key) {
      case "Enter": {
        R.preventDefault(), l !== _.typeaheadMulti ? v(E.props.value) : v(""), n?.(E.props.value), r?.(!1), O(0);
        break;
      }
      case "Escape": {
        r?.(!1);
        break;
      }
      case "Backspace": {
        l === _.typeahead && n?.("");
        break;
      }
      case "ArrowUp":
      case "ArrowDown": {
        R.preventDefault();
        let V = 0;
        R.key === "ArrowUp" && (x === 0 ? V = w.length - 1 : V = x - 1), R.key === "ArrowDown" && (x === w.length - 1 ? V = 0 : V = x + 1), O(V);
        break;
      }
    }
  };
  return /* @__PURE__ */ b(
    ce,
    {
      ...I,
      onClick: F,
      onOpenChange: () => r?.(!1),
      onSelect: (R, E) => n?.(E || ""),
      maxMenuHeight: se(d),
      popperProps: { direction: m, width: se(o) },
      toggle: (R) => /* @__PURE__ */ t(
        ee,
        {
          ref: R,
          id: e,
          variant: "typeahead",
          onClick: () => r?.(!0),
          icon: u,
          isExpanded: I.isOpen,
          isFullWidth: !0,
          status: i === "error" ? Ee.danger : void 0,
          children: /* @__PURE__ */ b(Xe, { isPlain: !0, children: [
            /* @__PURE__ */ t(
              et,
              {
                placeholder: s,
                value: l === _.typeahead && y ? y : N,
                onClick: F,
                onChange: (E, V) => {
                  v(V), a?.(V);
                },
                onKeyDown: (E) => A(E),
                autoComplete: "off",
                innerRef: f,
                role: "combobox",
                isExpanded: I.isOpen,
                "aria-controls": "select-typeahead-listbox",
                "aria-label": h,
                children: l === _.typeaheadMulti && Array.isArray(y) && (c || /* @__PURE__ */ t(tt, { ...T, children: y.map((E, V) => /* @__PURE__ */ t(
                  nt,
                  {
                    onClick: (W) => {
                      W.stopPropagation(), n?.(E);
                    },
                    children: E
                  },
                  V
                )) }))
              }
            ),
            /* @__PURE__ */ t(rt, { children: !!N && /* @__PURE__ */ t(
              P,
              {
                variant: "plain",
                onClick: () => {
                  n?.(""), v(""), a?.(""), f?.current?.focus();
                },
                "aria-label": "Clear input value",
                children: /* @__PURE__ */ t(ot, { "aria-hidden": !0 })
              }
            ) })
          ] })
        }
      ),
      children: [
        /* @__PURE__ */ t(de, { children: g }),
        p && /* @__PURE__ */ t(Wt, { children: p })
      ]
    }
  );
};
var _ = /* @__PURE__ */ ((e) => (e.single = "single", e.typeahead = "typeahead", e.typeaheadMulti = "typeaheadMulti", e))(_ || {});
const se = (e) => typeof e == "number" ? e + "px" : e, or = ({
  variant: e = "single",
  ...n
}) => e === "single" ? /* @__PURE__ */ t(ir, { ...n }) : /* @__PURE__ */ t(sr, { ...n, variant: e }), ze = (e) => {
  const { t: n, form: r, inputType: a, attribute: l } = e, [i, s] = C(!1), [d, o] = C(""), u = a === "multiselect", m = (p, g) => {
    u ? g.value.includes(p) ? g.onChange(g.value.filter((I) => I !== p)) : Array.isArray(g.value) ? g.onChange([...g.value, p]) : g.onChange([p]) : g.onChange(p === g.value ? "" : p);
  }, y = l.validators?.options?.options || [], h = l.annotations?.inputOptionLabels || {}, c = (p) => H(e.t, h[p], p), T = (p) => y.filter(
    (g) => c(g).toLowerCase().includes(d.toLowerCase())
  ).map((g) => /* @__PURE__ */ t(
    Oe,
    {
      selected: p === g,
      value: g,
      children: c(g)
    },
    g
  ));
  return /* @__PURE__ */ t(te, { ...e, children: /* @__PURE__ */ t(
    $,
    {
      name: B(l.name),
      defaultValue: "",
      control: r.control,
      render: ({ field: p }) => /* @__PURE__ */ t(
        or,
        {
          toggleId: l.name,
          onToggle: (g) => s(g),
          onClear: () => m("", p),
          onSelect: (g) => {
            const I = g.toString();
            m(I, p), Array.isArray(p.value) || s(!1);
          },
          selections: u && Array.isArray(p.value) ? p.value : c(p.value),
          variant: u ? _.typeaheadMulti : y.length >= 10 ? _.typeahead : _.single,
          "aria-label": n("selectOne"),
          isOpen: i,
          isDisabled: l.readOnly,
          onFilter: (g) => (o(g), T(p.value)),
          children: T(p.value)
        }
      )
    }
  ) });
}, cr = (e) => {
  const { form: n, attribute: r } = e, a = me(r);
  return /* @__PURE__ */ t(te, { ...e, children: /* @__PURE__ */ t(
    Hn,
    {
      id: r.name,
      "data-testid": r.name,
      ...n.register(B(r.name)),
      cols: r.annotations?.inputTypeCols,
      rows: r.annotations?.inputTypeRows,
      readOnly: r.readOnly,
      isRequired: a
    }
  ) });
}, M = (e) => {
  const { form: n, inputType: r, attribute: a } = e, l = me(a), i = r.startsWith("html") ? r.substring(6) : "text";
  return /* @__PURE__ */ t(te, { ...e, children: /* @__PURE__ */ t(
    oe,
    {
      id: a.name,
      "data-testid": a.name,
      type: i,
      placeholder: H(
        e.t,
        a.annotations?.inputTypePlaceholder
      ),
      readOnly: a.readOnly,
      isRequired: l,
      ...n.register(B(a.name))
    }
  ) });
}, Se = {
  text: M,
  textarea: cr,
  select: ze,
  "select-radiobuttons": je,
  multiselect: ze,
  "multiselect-checkboxes": je,
  "html5-email": M,
  "html5-tel": M,
  "html5-url": M,
  "html5-number": M,
  "html5-range": M,
  "html5-datetime-local": M,
  "html5-date": M,
  "html5-month": M,
  "html5-time": M,
  "multi-input": ar
}, $r = ({
  t: e,
  form: n,
  userProfileMetadata: r,
  supportedLocales: a,
  currentLocale: l,
  hideReadOnly: i = !1,
  renderer: s
}) => {
  const d = L(() => {
    if (!r.attributes)
      return [];
    const o = i ? r.attributes.filter(({ readOnly: u }) => !u) : r.attributes;
    return [
      // Insert an empty group for attributes without a group.
      { name: void 0 },
      ...r.groups ?? []
    ].map((u) => ({
      group: u,
      attributes: o.filter(
        (m) => m.group === u.name
      )
    }));
  }, [
    i,
    r.groups,
    r.attributes
  ]);
  return d.length === 0 ? null : /* @__PURE__ */ t(
    Yn,
    {
      label: e("jumpToSection"),
      sections: d.filter((o) => o.attributes.length > 0).map(({ group: o, attributes: u }) => ({
        title: H(e, o.displayHeader, o.name) || e("general"),
        panel: /* @__PURE__ */ b("div", { className: "pf-v5-c-form", children: [
          o.displayDescription && /* @__PURE__ */ t(Ce, { className: "pf-v5-u-pb-lg", children: H(e, o.displayDescription, "") }),
          u.map((m) => /* @__PURE__ */ t(
            dr,
            {
              t: e,
              form: n,
              supportedLocales: a,
              currentLocale: l,
              renderer: s,
              attribute: m
            },
            m.name
          ))
        ] })
      }))
    }
  );
}, dr = ({
  t: e,
  form: n,
  renderer: r,
  supportedLocales: a,
  currentLocale: l,
  attribute: i
}) => {
  const s = n.watch(
    B(i.name)
  ), d = L(() => hr(i), [i]), o = i.multivalued || mr(s) && i.annotations?.inputType === void 0 ? Se["multi-input"] : Se[d];
  return i.name === "locale" ? /* @__PURE__ */ t(
    tr,
    {
      form: n,
      supportedLocales: a,
      currentLocale: l,
      t: e,
      attribute: i
    }
  ) : /* @__PURE__ */ t(
    o,
    {
      t: e,
      form: n,
      inputType: d,
      attribute: i,
      renderer: r
    }
  );
}, ur = "text";
function hr(e) {
  if (yt(e.name))
    return "text";
  const n = e.annotations?.inputType;
  return pr(n) ? n : ur;
}
const pr = (e) => typeof e == "string" && e in Se, mr = (e) => Array.isArray(e) && e.length > 1, fr = ({
  className: e = "",
  border: n,
  size: r = "md"
}) => /* @__PURE__ */ b(
  "svg",
  {
    className: An(
      re.avatar,
      re.modifiers[r],
      n === "light" && re.modifiers.light,
      n === "dark" && re.modifiers.dark,
      e
    ),
    enableBackground: "new 0 0 36 36",
    version: "1.1",
    viewBox: "0 0 36 36",
    xmlns: "http://www.w3.org/2000/svg",
    children: [
      /* @__PURE__ */ t(
        "circle",
        {
          style: { fillRule: "evenodd", clipRule: "evenodd", fill: "#FFFFFF" },
          cx: "18",
          cy: "18.5",
          r: "18"
        }
      ),
      /* @__PURE__ */ t("defs", { children: /* @__PURE__ */ t(
        "filter",
        {
          id: "b",
          x: "5.2",
          y: "7.2",
          width: "25.6",
          height: "53.6",
          filterUnits: "userSpaceOnUse",
          children: /* @__PURE__ */ t("feColorMatrix", { values: "1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" })
        }
      ) }),
      /* @__PURE__ */ t(
        "mask",
        {
          id: "a",
          x: "5.2",
          y: "7.2",
          width: "25.6",
          height: "53.6",
          maskUnits: "userSpaceOnUse",
          children: /* @__PURE__ */ t("g", { style: { filter: 'url("#b")' }, children: /* @__PURE__ */ t(
            "circle",
            {
              style: { fillRule: "evenodd", clipRule: "evenodd", fill: "#FFFFFF" },
              cx: "18",
              cy: "18.5",
              r: "18"
            }
          ) })
        }
      ),
      /* @__PURE__ */ t("g", { style: { filter: 'url("#a")' }, children: /* @__PURE__ */ b("g", { transform: "translate(5.04 6.88)", children: [
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#BBBBBB"
            },
            d: "m22.6 18.1c-1.1-1.4-2.3-2.2-3.5-2.6s-1.8-0.6-6.3-0.6-6.1 0.7-6.1 0.7 0 0 0 0c-1.2 0.4-2.4 1.2-3.4 2.6-2.3 2.8-3.2 12.3-3.2 14.8 0 3.2 0.4 12.3 0.6 15.4 0 0-0.4 5.5 4 5.5l-0.3-6.3-0.4-3.5 0.2-0.9c0.9 0.4 3.6 1.2 8.6 1.2 5.3 0 8-0.9 8.8-1.3l0.2 1-0.2 3.6-0.3 6.3c3 0.1 3.7-3 3.8-4.4s0.6-12.6 0.6-16.5c0.1-2.6-0.8-12.1-3.1-15z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.1,
              fillRule: "evenodd",
              clipRule: "evenodd"
            },
            d: "m22.5 26c-0.1-2.1-1.5-2.8-4.8-2.8l2.2 9.6s1.8-1.7 3-1.8c0 0-0.4-4.6-0.4-5z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#BBBBBB"
            },
            d: "m12.7 13.2c-3.5 0-6.4-2.9-6.4-6.4s2.9-6.4 6.4-6.4 6.4 2.9 6.4 6.4-2.8 6.4-6.4 6.4z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.08,
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#231F20"
            },
            d: "m9.4 6.8c0-3 2.1-5.5 4.9-6.3-0.5-0.1-1-0.2-1.6-0.2-3.5 0-6.4 2.9-6.4 6.4s2.9 6.4 6.4 6.4c0.6 0 1.1-0.1 1.6-0.2-2.8-0.6-4.9-3.1-4.9-6.1z"
          }
        ),
        /* @__PURE__ */ t(
          "path",
          {
            style: {
              opacity: 0.1,
              fillRule: "evenodd",
              clipRule: "evenodd"
            },
            d: "m8.3 22.4c-2 0.4-2.9 1.4-3.1 3.5l-0.6 18.6s1.7 0.7 3.6 0.9l0.1-23z"
          }
        )
      ] }) })
    ]
  }
), Je = ({
  isKebab: e = !1,
  title: n,
  dropDownItems: r,
  ...a
}) => {
  const [l, i] = C(!1);
  return /* @__PURE__ */ t(
    jt,
    {
      ...a,
      popperProps: {
        position: "right"
      },
      toggle: (s) => /* @__PURE__ */ t(
        ee,
        {
          "data-testid": `${a["data-testid"]}-toggle`,
          ref: s,
          onClick: () => i(!l),
          isExpanded: l,
          variant: e ? "plain" : "default",
          children: e ? /* @__PURE__ */ t(bn, {}) : n
        }
      ),
      isOpen: l,
      children: /* @__PURE__ */ t(zt, { children: r })
    }
  );
};
function gr(e, n) {
  if (!e)
    return n("unknownUser");
  const r = e.given_name, a = e.family_name, l = e.preferred_username;
  return r && a ? n("fullName", { givenName: r, familyName: a }) : r || a || l || n("unknownUser");
}
const Wr = ({
  keycloak: e,
  brand: { href: n, ...r },
  avatar: a,
  features: {
    hasLogout: l = !0,
    hasManageAccount: i = !0,
    hasUsername: s = !0
  } = {},
  kebabDropdownItems: d,
  dropdownItems: o = [],
  toolbarItems: u,
  ...m
}) => {
  const { t: y } = Pe(), h = [];
  i && h.push(
    /* @__PURE__ */ t(
      He,
      {
        onClick: () => e.accountManagement(),
        children: y("manageAccount")
      },
      "manageAccount"
    )
  ), l && h.push(
    /* @__PURE__ */ t(He, { onClick: () => e.logout(), children: y("signOut") }, "signOut")
  );
  const c = e.idTokenParsed?.picture;
  return /* @__PURE__ */ t(
    kn,
    {
      ...m,
      logo: /* @__PURE__ */ t(Jt, { ...r }),
      logoProps: { href: n },
      headerTools: /* @__PURE__ */ b(wn, { children: [
        /* @__PURE__ */ b(Sn, { children: [
          /* @__PURE__ */ t(
            ye,
            {
              visibility: {
                md: "hidden"
              },
              children: /* @__PURE__ */ t(
                Je,
                {
                  "data-testid": "options-kebab",
                  isKebab: !0,
                  dropDownItems: [
                    ...d || o,
                    h
                  ]
                }
              )
            }
          ),
          /* @__PURE__ */ t(ye, { children: u }),
          /* @__PURE__ */ t(
            ye,
            {
              visibility: {
                default: "hidden",
                md: "visible"
              },
              children: /* @__PURE__ */ t(
                Je,
                {
                  "data-testid": "options",
                  dropDownItems: [...o, h],
                  title: s ? gr(e.idTokenParsed, y) : void 0
                }
              )
            }
          )
        ] }),
        c || a?.src ? /* @__PURE__ */ t(Zt, { src: c, alt: y("avatar"), ...a }) : /* @__PURE__ */ t(fr, { ...a })
      ] })
    }
  );
};
export {
  Tn as AlertProvider,
  Dr as ContinueCancelModal,
  Fn as ErrorPage,
  ut as FormErrorText,
  Wn as FormPanel,
  Br as FormSubmitButton,
  Mn as Help,
  ht as HelpItem,
  qr as IconMapper,
  Wr as KeycloakMasthead,
  Or as KeycloakProvider,
  or as KeycloakSelect,
  Hn as KeycloakTextArea,
  Pr as NumberControl,
  Nr as PasswordControl,
  pt as PasswordInput,
  Yn as ScrollForm,
  Un as SelectControl,
  K as SelectVariant,
  Mr as SwitchControl,
  Lr as TextAreaControl,
  _r as TextControl,
  $r as UserProfileFields,
  Ur as beerify,
  En as createNamedContext,
  Hr as debeerify,
  Vr as getInjectedEnvironment,
  On as isDefined,
  Kr as isUserProfileError,
  H as label,
  Zn as mainPageContentId,
  Gr as setUserProfileServerError,
  Rr as useAlerts,
  Er as useEnvironment,
  Nn as useHelp,
  Vn as useRequiredContext,
  Pn as useStoredState
};
